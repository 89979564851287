import React from 'react';

const Footer = () => {

    return (
      <footer className="app-footer">
          <div className="container-fluid footer_container">
              <div className="row">
                  <div className="col">
                      <p className="footer_container-info">&#169; 2020 Embutidos La Tradicion</p>
                  </div>
              </div>
          </div>
      </footer>
    );

}

export default Footer;