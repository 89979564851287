import React from 'react';
import { Profile } from '../utils/Products';
import './ProductList.scss';

const Contactos = () => {
    return (
      <main className="app-section product_main-section">
        <div className="container">
          <article className="row product_profile-container">
              <div className="col">
                <div className="row">
                    <div className="col-lg-5 d-none d-sm-block">
                    <div className="product_profile-img-frame">
                        <img src={`${process.env.ASSETS_URL}sqr-chorizos.jpg`} alt="Embutidos La Tradicion" className="product_profile-img" />
                    </div>
                    </div>
                    {Profile && 
                      <div className="col-lg-7">
                        <h1 className="product_profile-body--title">Contactos</h1>
                        <p className="product_profile-body--paragraph">
                          Estamos ubicados en:<br/>San Crist&oacute;bal<br/>Estado Tachira.<br/>Venezuela.<br/><br/>
                          Comunicate con nosotros a traves de los n&uacute;meros:<br/>
                          <span className="product_profile-body--paragraph--highlighted">
                            <a href="tel:+584147053740"
                              className="product_profile-body--phone" 
                              title="Embutidos la Tradicion">0414 7053740</a>
                          </span><br/>
                          <span className="product_profile-body--paragraph--highlighted">
                            <a href="tel:+5802763489482"
                              className="product_profile-body--phone" 
                              title="Embutidos la Tradicion">0276 3489482</a>
                          </span>
                        </p>
                      </div>
                    }
                </div>
              </div>
          </article>
        </div>
      </main>
    );
}

export default Contactos;

