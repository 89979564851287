import React from 'react';
import Nav from '../containers/Nav';
import './HeroHeader.scss';

const HeroHeader = () => {

    return (
    <header className="container-fluid hero-header_container">
        <div className="row display-flex">
            <div className="col-sm-3">
                <div className="hero-header_content">
                    <img 
                        src={`${process.env.ASSETS_URL}embutidoslatradicion-logo.png`} 
                        alt="Embutidos La Tradicion"
                        className="hero-header_logo"
                    />
                </div>
            </div>
            <div className="col-sm-9">
                <Nav />
            </div>
        </div>
        <div className="row hero-header_hero">
            <img 
                src={require('../assets/landscape-hot-dogs.jpg?v1')} 
                alt="Embutidos La Tradicion"
                className="hero-header_hero--img"
            />
        </div>
    </header>
    );

}

export default HeroHeader;

