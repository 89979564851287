import React from 'react';
import { Link } from 'react-router-dom';
import './Nav.scss';

const Nav = () => {

    return (
    <header className="container-fluid nav_container">
        <div className="row">
            <div className="col-sm-12 nav_content">
              <ul className="nav_menu-wrapper">
                <li className="nav_menu-item">
                  <Link to="/nuestra-tradicion">Nuestra Tradici&oacute;n</Link>
                </li>
                <li className="nav_menu-item">
                  <a href="https://embutidoslatradicion.com/assets/embutidos-la-tradicion_catalogo.pdf" 
                    target="_blank"
                    >Catalogo
                  </a>
                </li>
                <li className="nav_menu-item">
                  <Link to="/contactos">Contactos</Link>
                </li>
              </ul>
            </div>
        </div>
    </header>
    );

}

export default Nav;
