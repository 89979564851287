export const Products = {
  "cards": [
    {
      "asset": "sqr-100x100venezolano.jpg",
      "paragraph": "Embutidos la Tradicion"
    },
    {
      "asset": "sqr-100x100carne.jpg",
      "paragraph": "Embutidos la Tradicion"
    },
    {
      "asset": "sqr-chorizos.jpg",
      "paragraph": "Embutidos la Tradicion"
    },
    {
      "asset": "sqr-salchicha.jpg",
      "paragraph": "Embutidos la Tradicion"
    }
  ]
}

/*
export const Products = {
SALAMI TRADICION
CHORIZO ESPAÑOL 
PEPERONNI
SALCHICHA TIPO FRANFURK
SALCHICHA TIPO POLACA
SACHICHAS DE POLLO
SALCHICHA TIPO KOSACA
SALCHICHA WINNER
JAMON ESPALDA
JAMON AHUMADO  
LONGANIZA
CHORIZO AHUMADO
CHORIZO URUGUAYO
CHISTORRA
MORTADELA  DE CARNE
}
*/

export const Profile = {
  "name": "Embutidos La Tradici&oacute;n",
  "logo": "assets/embutidoslatradicion-logo.png",
  "history": "Somos una empresa de embutidos creada desde año 2012 con la finalidad de satisfacer el mercado local y    regional de embutidos comercializada bajo la marca <strong>Embutidos La Tradici&oacute;n</strong>, nuestro comienzo fue con una diversidad de chorizos para luego ir incluyendo a la línea de producci&oacute;n jamones, salamis y por ultimo pastas finas como mortadela y salchichas, al dia de hoy ofrecemos una  variedad de embutidos los cuales son distribuidos por una amplia red de aliados comerciales. <br/><br/>Seguimos mejorando cada dia y apostando al pa&iacute;s pues la inversi&oacute;n en planta ha sido constante y los resultados se han visto en la satisfacci&oacute;n nuestros clientes que cada dia seguimos sumando mas. La empresa contin&uacute;a avanzando, los a&ntilde;os por venir ser&aacute;n de cambios definitivos, que marcaran su historia para el futuro.",
  "mision": "Ofrecemos alimentos para consumo humano, mediante procesos efectivos e innovadores orientados al cliente, con equipos de alto desempe&ntilde;o, generando prosperidad colectiva para nuestros clientes, trabajadores, accionista y la sociedad.",
  "values": [
    {
      "label": "Confianza",
      "desc": "Nos caracterizan el respeto, la  puntualidad, la coherencia y el aprendizaje.<br/>Gente confiable"
    },
    {
      "label": "Pasi&oacute;n",
      "desc": "Actuamos para ser mejores en el logro de nuestras metas, con iniciativa y perseverancia.<br/>La pasi&oacute;n nos mueve"
    },
    {
      "label": "Trabajo en Equipo",
      "desc": "Nuestro compromiso es apoyarnos y comunicarnos, aportando ideas para potenciar los resultados.<br/>En equipo somos m&aacute;s"
    },
  ]

}
