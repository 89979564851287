import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HeroHeader from '../containers/HeroHeader';
import ProductList from '../containers/ProductList';
import CompanyProfile from '../containers/CompanyProfile';
import Contactos from '../containers/Contactos';
import Error from '../containers/Error';
import Footer from '../containers/Footer';

const App = () => {

  return (
    <>
      <BrowserRouter>
        <HeroHeader />
        <Routes>
          <Route path="/" element={<ProductList/>} />
          <Route path="/nuestra-tradicion" element={<CompanyProfile/>} />
          <Route path="/contactos" element={<Contactos/>} />
          <Route element={<Error/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );

}

export default App