import React from 'react';
import './HeroHeader.scss';

const Error = () => {

  return (
    <header className="container">
        <div className="row">
            <div>
              <p>Seems like you got lost!</p>
            </div>
        </div>
    </header>
  );

}

export default Error;