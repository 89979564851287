import React from 'react';
import ImageSlider from './ImageSlider';
import { Products, Profile } from '../utils/Products';
import './ProductList.scss';

const ProductList = () => {
  const items = Products.cards.map(card => {
    return { 
        src: `${process.env.ASSETS_URL}${card.asset}`,
        caption: `${card.paragraph}`
    }
    })

    return (
        <main className="app-section product_main-section">
          <div className="container">
            <article className="row product_profile-container">
              <div className="col-lg-5">
              <div className="product_slider-frame">
                  <ImageSlider items={items} />
              </div>
              </div>
              {Profile && 
                <div className="col-lg-7">
                  <h1 className="product_profile-body--title"
                  dangerouslySetInnerHTML={{ __html: Profile.name }} />
                  <p className="product_profile-body--paragraph"
                  dangerouslySetInnerHTML={{ __html: Profile.history }} />
                </div>
              }
            </article>
          </div>
        </main>
    );
}

export default ProductList;

